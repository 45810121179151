import { useStaticQuery, graphql } from 'gatsby'

export default function useGetAllSymbol(name) {
    const data = useStaticQuery(graphql`
        query {
            allBuilderModels {
                symbol(options: { cachebust: true }) {
                    id
                    name
                    content
                }
            }
        }
    `)
    return name ?
        data.allBuilderModels.symbol.find(symbol => symbol.name === name)
        :
        data.allBuilderModels.symbol
}
