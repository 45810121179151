import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import { communityBase } from './electronic-drum-kits-nav'

const communityLink = theme => [
    communityBase,
    css({
        display: 'block',
        marginBottom: 16,
    })
]

export default function CommunityArticleLinks({ emotion = communityLink }) {
    const query = useStaticQuery(graphql`
        query CommunityArticleQuery {
            allBuilderModels {
                alesisDrumsCommunityArticleModel(limit: 5, sort: { lastUpdated: ASC }) {
                    data {
                        siteUrl
                        title
                    }
                    lastUpdated
                }
            }
        }
    `)
    const communityArticles = query?.allBuilderModels?.alesisDrumsCommunityArticleModel?.map(({ data }) => data)
    return communityArticles.map(({ title, siteUrl }) =>
        <Link
            to={siteUrl}
            css={emotion}
        >
            {title}
        </Link>
    )
}
