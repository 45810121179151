import React from 'react'
import { Link } from 'gatsby'
import { base, Popular } from '../components/electronic-drum-kits-nav'
import { css } from '@emotion/react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CommunityArticleLinks from '../components/community-article-links'

const searchLink = theme => [
    base,
    css({
        display: 'block',
        fontFamily: theme.fonts.primary,
        fontSize: 12,
        marginBottom: 16,
    })
]

const searchHeader = theme => [
    searchLink,
    css({
        fontFamily: theme.fonts.secondary,
        fontSize: 11,
        marginBottom: 24,
    })
]

export default function SearchLinks({ style }) {
    return (
        <Row className='justify-content-center' style={style}>
            <Col lg={8}>
                <nav>
                    <Row>
                        <Col sm className='mb-4 mb-sm-0'>
                            <Link to='/electronic-drum-kits' css={searchHeader}>
                                Electronic Drum Kits
                            </Link>
                            <Popular emotion={searchLink} />
                        </Col>
                        <Col>
                            <Link to='/community' css={searchHeader}>
                                Community
                            </Link>
                            <CommunityArticleLinks emotion={searchLink} />
                        </Col>
                    </Row>
                </nav>
            </Col >
        </Row >
    )
}
