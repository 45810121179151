const ls = require('local-storage')

// https://dev.maxmind.com/geoip/geolocate-an-ip/client-side-javascript
module.exports = () => {
    const country = ls.get('country')
    if (country) return

    const onSuccess = response => {
        ls.set('country', response.country.iso_code)
    }

    const onError = error => {
        console.error(error)
    }

    window.geoip2.country(onSuccess, onError, {})
}
