import React from 'react'
import facebook from '../svgs/facebook-icon-white.svg'
import twitter from '../svgs/twitter-x-icon-white.svg'
import instagram from '../svgs/instagram-icon-white.svg'
import youtube from '../svgs/youtube-icon-white.svg'

export default function SocialMedia() {
    return (
        <div className='d-flex' style={{ gap: 32 }}>
            <a href='https://www.facebook.com/Alesis/' target='_blank' rel='noreferrer'>
                <img src={facebook} alt='facebook icon white' height={16} width={16} />
            </a>
            <a href='https://twitter.com/Alesis' target='_blank' rel='noreferrer'>
                <img src={twitter} alt='facebook icon white' height={16} width={16} />
            </a>
            <a href='https://instagram.com/alesis_gear/' target='_blank' rel='noreferrer'>
                <img src={instagram} alt='facebook icon white' height={16} width={16} />
            </a>
            <a href='https://www.youtube.com/user/AlesisVideo' target='_blank' rel='noreferrer'>
                <img src={youtube} alt='facebook icon white' height={16} width={16} />
            </a>
        </div>
    )
}