import React from 'react'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BrandLogoLink from './brand-logo-link'
import SocialMedia from './social-media'
import { css } from '@emotion/react'
import NavLinks from './nav-links'
import { base, hover } from '../emotion/anchor-css'
import alesis from '../svgs/alesis-logo-white.svg'
import inmusicLogoBlack from '../svgs/inmusic-logo-black.svg'
import { BuilderComponent } from "@builder.io/react"
import useGetAllSymbol from '../hooks/useGetAllSymbol'

const label = css({
    color: 'white',
    fontSize: 11,
    fontWeight: 700,
    marginBottom: 24,
})

const link = theme => [base, hover(theme)]

const legalLinks = css({
    color: 'black',
    '&:hover': {
        textDecoration: 'underline'
    }
})

export default function Footer() {
    const emailSignup = useGetAllSymbol('AlesisDrums Email Signup')
    return (
        <>
            {emailSignup && <BuilderComponent model="symbol" content={emailSignup.content} />}

            {/* MAIN */}
            <Container fluid className='bg-black'>
                <Container css={{ paddingTop: 40, paddingBottom: 32 }}>
                    <div style={{ borderTop: '1px solid #808080' }}>
                        <Row className='justify-content-between' style={{ padding: '40px 0px', }}>
                            <Col xs='auto'>
                                <BrandLogoLink />
                            </Col>
                            <Col xs='auto'>
                                <SocialMedia />
                            </Col>
                        </Row>
                    </div>
                    <Row css={{
                        '.col-6': {
                            marginBottom: 40,

                            '& > div': {
                                display: 'flex',
                                gap: 16,
                                flexDirection: 'column',
                                textTransform: 'capitalize',
                            }
                        },
                        'a': {
                            textWrap: 'nowrap'
                        }
                    }}>
                        <Col xs={6} sm>
                            <p css={label}>Products</p>
                            <div>
                                <NavLinks disableHoverNav emotion={link} />
                                <a href='https://www.alesis.com/products/browse/category/legacy.html' target="_blank" rel="noreferrer" css={link}>Legacy</a>
                            </div>
                        </Col>
                        <Col xs={6} sm>
                            <p css={label}>Learn</p>
                            <div>
                                {/* <Link to='/compare-electronic-drum-kits' css={link}>
                                    Compare Electronic Drum Kits
                                </Link> */}
                                <Link to='/dealers' css={link}>
                                    find a dealer
                                </Link>
                                <a href='https://support.alesis.com/' target='_blank' rel='noreferrer' css={link}>
                                    Knowledge base
                                </a>
                            </div>
                        </Col>
                        <Col xs={6} sm>
                            <p css={label}>Community</p>
                            <div>
                                <Link to='/community' css={link}>
                                    explore the community
                                </Link>
                                <Link to='/community' css={link}>
                                    guides
                                </Link>
                                <Link to='/community' css={link}>
                                    features
                                </Link>
                                <Link to='/community' css={link}>
                                    videos
                                </Link>
                                <Link to='/community' css={link}>
                                    news
                                </Link>
                            </div>
                        </Col>
                        <Col xs={6} sm>
                            <p css={label}>Company</p>
                            <div>
                                <a href='https://www.alesis.com/company.html' target='_blank' rel='noreferrer' css={link}>
                                    About
                                </a>
                                <a href='https://www.inmusicbrands.com/careers/' target='_blank' rel='noreferrer' css={link}>
                                    Careers
                                </a>
                                <a href='https://www.alesis.com/company.html' target='_blank' rel='noreferrer' css={link}>
                                    history
                                </a>
                                <Link to='/privacy' css={link}>
                                    privacy
                                </Link>
                                <a href='https://support.alesis.com/en/support/home' target='_blank' rel='noreferrer' css={link}>
                                    Contact
                                </a>
                            </div>
                        </Col>
                        <Col xs={6} sm>
                            <p css={label}>Support</p>
                            <div>
                                <a
                                    href="https://profile.inmusicbrands.com/customer/account/login/"
                                    target='_blank'
                                    rel='noreferrer'
                                    css={link}
                                >
                                    My Account
                                </a>
                                <Link to='/downloads' css={link}>Downloads</Link>
                                <a href='https://www.alesis.com/repairs' target='_blank' rel='noreferrer'  css={link}>
                                    Repairs
                                </a>
                                <a
                                    href="https://support.alesis.com/"
                                    target='_blank'
                                    rel='noreferrer'
                                    css={link}
                                >
                                    Contact Support
                                </a>
                                <a href='https://www.alesis.com/media.html' target='_blank' rel='noreferrer' css={link}>
                                    media resources
                                </a>
                                <a href='https://support.alesis.com/' target='_blank' rel='noreferrer' css={link}>
                                    Knowledge base
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* ALESIS */}
            <Container fluid style={{ backgroundColor: '#00B2FF' }} className='d-none d-sm-inline-block'>
                <Container className='d-flex justify-content-between align-items-center flex-column flex-sm-row py-2'>
                    <p className='mb-0' css={theme => css({
                        fontFamily: theme.fonts.primaryHeavy,
                        fontSize: 14,
                        color: 'white',
                    })}>
                        Looking for Keyboards?
                    </p>
                    <a
                        href='https://www.alesis.com/'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img src={alesis} alt='alesis (not alesis drums) logo' />
                    </a>
                    <p className='mb-0 text-white' style={{ fontSize: 12 }}>
                        Find keyboards and more at&nbsp;
                        <a
                            href='https://www.alesis.com/'
                            target='_blank'
                            rel='noreferrer'
                            css={theme => css({
                                fontFamily: theme.fonts.primaryHeavy,
                                fontSize: 14,
                            })}
                            className='text-white text-uppercase'
                        >
                            alesis.com
                        </a>
                    </p>
                </Container>
            </Container>

            {/* INMUSIC */}
            <Container fluid style={{ backgroundColor: '#D2D2D2' }}>
                <Container style={{ paddingTop: 40, paddingBottom: 40 }}>
                    <Row className='mb-5'>
                        <Col xs='auto'>
                            <a
                                href="https://www.inmusicbrands.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={inmusicLogoBlack}
                                    alt='inmusic logo black'
                                    className='d-inline-block'
                                />
                            </a>
                        </Col>
                        <Col>
                            <div style={{
                                backgroundColor: 'black',
                                height: 1,
                                transform: 'scaleY(.5)', // achieve half pixel
                                width: '100%',
                                margin: '16px 0px',
                            }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{fontSize: 12}}>
                                <span>© {new Date().getFullYear()} inMusic, Inc. All Rights Reserved.</span>&nbsp;
                                <Link to="/dealers" css={legalLinks}>Dealers</Link>&nbsp;|&nbsp;
                                <a href="https://support.alesis.com/" target="_blank" rel="noreferrer" css={legalLinks}>Support</a>&nbsp;|&nbsp;
                                <a href="https://www.inmusicbrands.com/careers/" target="_blank" rel="noreferrer" css={legalLinks}>Careers</a>&nbsp;|&nbsp;
                                <Link to='/privacy' css={legalLinks}>Privacy</Link>&nbsp;|&nbsp;
                                <Link to='/terms-of-use' css={legalLinks}>Terms of Use</Link>&nbsp;|&nbsp;
                                <div id="teconsent" className='d-inline' css={legalLinks} />
                            </div>
                        </Col>
                        <Col sm='auto'>
                            <p style={{ fontSize: 14, fontWeight: 800 }}>
                                Part of the inMusic family of brands
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}