import React from 'react'
import { Link } from 'gatsby'
// https://emotion.sh/docs/best-practices#consider-defining-styles-outside-your-components
import { css } from '@emotion/react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CommunityArticleLinks from './community-article-links'

export const base = theme => css({
    textTransform: 'capitalize',
    color: 'white',
    '&:hover': {
        color: theme.colors.primary
    }
})

export const communityBase = theme => [
    base,
    css({
        fontFamily: theme.fonts.primary,
        fontSize: 12,
    })
]

const label = css({
    color: 'white',
    fontSize: 11,
    marginBottom: 20,
})

const link = theme => [
    base,
    css({
        fontFamily: theme.fonts.primaryHeavy,
        display: 'block',
        marginBottom: 16,
    })
]

const exploreAllCommunityPosts = theme => [
    communityBase, 
    css({
        fontFamily: theme.fonts.primaryHeavy,
        marginBottom: 0,
    })
]

export const Popular = ({ emotion }) => <>
    <Link to='/electronic-drum-kits/strata-prime' css={emotion}>
        Strata Prime
    </Link>
    <Link to='/electronic-drum-kits/strata-core' css={emotion}>
        Strata Core
    </Link>
    <Link to='/electronic-drum-kits/nitro-pro' css={emotion}>
        Nitro Pro
    </Link>
    <Link to='/electronic-drum-kits/nitro-max-kit' css={emotion}>
        Nitro Max
    </Link>
</>

export default function ElectronicDrumKitsNav() {
    return (
        <nav>
            <Row>
                <Col>
                    <p css={label}>Products</p>
                    <Link to='/electronic-drum-kits' className='d-block mb-4' css={[link, { fontSize: 20 }]}>
                        Explore All Electronic Drum Kits
                    </Link>
                    <p css={label}>Popular</p>
                    <Popular emotion={link} />
                </Col>
                <Col>
                    <p css={label}>Collections</p>
                    <Link to='/electronic-drum-kits/the-strata-range' className='d-block mb-3' css={link}>
                        Explore Strata | Pro Level E-Drums
                    </Link>
                    <Link to='/electronic-drum-kits/the-nitro-range' className='d-block mb-3' css={link}>
                        Explore Nitro | Compact Practice E-Kits
                    </Link>
                    {/* <Link to='/electronic-drum-kits/bedroom-kits' className='d-block mb-3' css={link}>
                        bedroom kits
                    </Link> */}
                </Col>
                <Col>
                    <p css={label}>Community</p>
                    <CommunityArticleLinks />
                    <Link to='/community' className='d-block mb-3' css={exploreAllCommunityPosts}>
                        Explore All Community Posts
                    </Link>
                </Col>
            </Row>
        </nav>
    )
}