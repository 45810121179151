import React, { useContext } from 'react'
import AppContext from '../context/app'
import Container from 'react-bootstrap/Container'
import { css } from '@emotion/react'
import ElectronicDrumKitsNav from '../components/electronic-drum-kits-nav'
import SearchForm from '../algolia/search-form'
import SearchLinks from '../algolia/search-links'

const container = css({
    position: 'fixed',
    width: '100%',
    height: 'calc(100vh - 76px)',
    zIndex: 2020,
    top: 76,
})

const searchFormCss = css({
    fontSize: 14,
    padding: '16px 24px',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    color: 'white',
    caretColor: 'white',
    backgroundColor: 'transparent',
    '&:focus': {
        color: 'white',
        backgroundColor: 'transparent',
    },
    '&::placeholder': {
        color: '#808080',
        fontStyle: 'italic',
    }
})

const searchFormButtonCss = css({
    width: 80,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: 'white',
    },
    '& img': {
        filter: 'invert(1)'
    }
})

export default function HoverNav() {
    const { showBlurOverLay, setShowBlurOverlay } = useContext(AppContext)
    return (
        <div className='d-flex flex-column' css={container}>
            <Container fluid className='bg-black'>
                <Container style={{ paddingTop: 32, paddingBottom: 48 }}>
                    {showBlurOverLay === 'electronic-drum-kits-nav' && <ElectronicDrumKitsNav />}
                    {showBlurOverLay === 'search' && <>
                        <SearchForm formCss={searchFormCss} buttonCss={searchFormButtonCss} />
                        <SearchLinks style={{ marginTop: 40 }} />
                    </>}
                </Container>
            </Container>
            <div
                onMouseEnter={() => setShowBlurOverlay('')}
                onClick={() => setShowBlurOverlay('')}
                className='flex-grow-1'
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    backdropFilter: 'blur(5px)',
                }}
            />
        </div>
    )
}
