import React from 'react'
import { navigate } from 'gatsby'
import { useSearchBox, useInfiniteHits } from 'react-instantsearch'
import searchIconWhite from '../svgs/search-icon-white.svg'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function SearchForm({ style, formCss, buttonCss }) {
    const { query, refine, clear } = useSearchBox()
    const { results } = useInfiniteHits()

    async function handleSubmit(e) {
        e.preventDefault()
        e.stopPropagation()
        await navigate('/search', { state: results })
        clear()
    }

    return (
        <Row className='justify-content-center' style={style}>
            <Col lg={8}>
                <Form onSubmit={handleSubmit}>
                    <InputGroup>
                        <Form.Control
                            placeholder='Search Alesis Drums'
                            aria-label='Search Alesis Drums'
                            aria-describedby='Search Alesis Drums'
                            onChange={e => refine(e.target.value)}
                            value={query}
                            autoComplete='off'
                            autoFocus
                            css={formCss}
                        />
                        <Button
                            variant=''
                            type='submit'
                            css={buttonCss}
                        >
                            <img
                                src={searchIconWhite}
                                alt='search icon black'
                            />
                        </Button>
                    </InputGroup>
                </Form>
            </Col>
        </Row>
    )
}
