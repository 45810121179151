import React, { useContext, useRef } from 'react'
import AppContext from '../context/app'
import { Link } from 'gatsby'

export default function NavLinks(props) {
    const { disableHoverNav, emotion } = props
    const { clientWidth, showBlurOverLay, setShowBlurOverlay } = useContext(AppContext)
    const edkRef = useRef(null)

    return (
        <>
            <Link
                to='/electronic-drum-kits'
                css={emotion}
                ref={edkRef}
                className={`${showBlurOverLay === 'electronic-drum-kits-nav' ? 'text-primary' : ''}`}
                onMouseEnter={() => {
                    if (disableHoverNav) return
                    if (clientWidth < 768) return
                    
                    setTimeout(() => {
                        // only show the nav if the link is still being hovered
                        if (edkRef.current.matches(':hover')) {
                            setShowBlurOverlay('electronic-drum-kits-nav')
                        }
                    }, 300)
                }}
            >
                Electronic Drum Kits
            </Link>
            <Link
                to='/multipads-and-drum-machines'
                css={emotion}
                onMouseEnter={() => setShowBlurOverlay('')}
            >
                Multipads & Drum Machines
            </Link>
            <Link
                to='/drum-amplification'
                css={emotion}
                onMouseEnter={() => setShowBlurOverlay('')}
            >
                Drum Amplification
            </Link>
            <Link
                to='/accessories'
                css={emotion}
                onMouseEnter={() => setShowBlurOverlay('')}
            >
                Accessories
            </Link>
        </>
    )
}