import React, { useRef, useState } from 'react'
import { base, hover } from '../emotion/anchor-css'
import { css } from '@emotion/react'
import Container from 'react-bootstrap/Container'
import chevronRightWhite from '../svgs/chevron-right-white.svg'
import NavLinks from './nav-links'

const padding = css({
    paddingTop: 16,
    paddingBottom: 16,
    textWrap: 'nowrap'
})

export default function MobileSubNav() {
    const scrollContainerRef = useRef(null)
    const [scrollToRight, setScrollToRight] = useState(true)
    return (
        // display at lg and above is none
        <nav
            className='d-lg-none'
            style={{
                position: 'fixed',
                top: 76,
                zIndex: 1020,
                width: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(10px)',
            }}
        >
            <Container
                ref={scrollContainerRef}
                className='d-flex justify-content-sm-center overflow-auto position-relative'
                css={css({
                    gap: 32,
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    }
                })}
            >
                <NavLinks emotion={theme => [base, padding, hover(theme)]} />

                {/* SCROLL TO RIGHT / LEFT BUTTON */}
                <button
                    className='d-sm-none position-fixed'
                    style={
                        scrollToRight ?
                            {
                                right: 0,
                                borderLeftWidth: 1,
                            }
                            :
                            {
                                left: 0,
                                borderRightWidth: 1,
                            }
                    }
                    css={{
                        top: 0,
                        height: 50,
                        borderRadius: 0,
                        borderWidth: 0,
                        borderStyle: 'solid',
                        borderColor: '#808080',
                        padding: '6px 12px',
                        backgroundColor: 'rgba(0, 0, 0, 0.9)',
                        boxShadow: '-4px 0px 8px 0px rgba(0, 0, 0, 0.25)',
                    }}
                    onClick={() => {
                        const { scrollWidth } = scrollContainerRef.current
                        const options = {
                            left: scrollToRight ? scrollWidth : 0,
                            behavior: 'smooth'
                        }
                        scrollContainerRef.current.scroll(options)
                        setScrollToRight(!scrollToRight)
                    }}
                >
                    <img
                        src={chevronRightWhite}
                        alt=''
                        style={scrollToRight ? {} : { transform: 'rotate(180deg)' }}
                    />
                </button>
            </Container>
        </nav>
    )
}