// https://emotion.sh/docs/best-practices#consider-defining-styles-outside-your-components
import { css } from '@emotion/react'

export const base = theme => css({
    color: 'white',
    fontSize: 12,
    fontFamily: theme.fonts.primary
})

export const padding = css({
    paddingTop: 29,
    paddingBottom: 29,
    '@media(max-width: 991px)': {
        paddingTop: 27,
        paddingBottom: 27,
    },
})

export const hover = theme => css({
    '&:hover': {
        color: theme.colors.primary
    }
})
