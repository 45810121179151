import React, { useState, useEffect } from 'react'
import { css, ThemeProvider } from '@emotion/react'
import * as styles from '../styles/exports.module.scss'
import AppContext from '../context/app'
import Navbar from '../components/navbar'
import MobileSubNav from '../components/mobile-sub-nav'
import BlurOverlay from '../components/blur-overlay'
import Footer from '../components/footer'
import SearchProvider from '../algolia/search-provider'

/**
 * So that the navbar can have 80% opacity
 * And on a white background not seem gray
 */
const blackBarAboveEveryPageBehindNavbar = css({
    ':before': {
        display: 'block',
        position: 'absolute',
        background: 'black',
        content: "''",
        width: '100%',
        height: 76,
        top: 0,
    }
})

export default function Layout({ children }) {
    const [screenWidth, setScreenWidth] = useState(typeof window !== `undefined` ? window.screen.width : null)
    const [screenHeight, setScreenHeight] = useState(typeof window !== `undefined` ? window.screen.height : null)
    const [clientWidth, setClientWidth] = useState(typeof document !== `undefined` ? document.body.clientWidth : null)
    const [showBlurOverLay, setShowBlurOverlay] = useState('')
    
    useEffect(() => {
        const handleResize = e => {
            setScreenWidth(e.target.screen.width)
            setScreenHeight(e.target.screen.height)
            setClientWidth(e.target.document.body.clientWidth)
        }
        const handleNavigate = e => {
            setShowBlurOverlay('')
        }
        window.addEventListener('resize', handleResize)
        window.addEventListener('navigate', handleNavigate)
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('navigate', handleNavigate)
        }
    }, [])

    const value = {
        screenWidth,
        screenHeight,
        clientWidth,
        showBlurOverLay,
        setShowBlurOverlay
    }

    // https://emotion.sh/docs/theming
    const theme = {
        colors: {
            primary: styles.primaryColor,
            secondary: styles.secondaryColor,
        },
        fonts: {
            primary: styles.primaryFont,
            primaryHeavy: styles.primaryFontHeavy,
            secondary: styles.secondaryFont
        }
    }
    
    return (
        <AppContext.Provider value={value}>
            <ThemeProvider theme={theme}>
                <SearchProvider>
                    <Navbar />
                    <MobileSubNav />
                    {showBlurOverLay && <BlurOverlay />}
                    <main css={blackBarAboveEveryPageBehindNavbar}>{children}</main>
                    <Footer />
                </SearchProvider>
            </ThemeProvider>
        </AppContext.Provider>
    )
}