import React, { useContext, } from 'react'
import AppContext from '../context/app'
import Button from 'react-bootstrap/Button'
import searchIcon from '../svgs/search-icon-white.svg'
import xCloseIcon from '../svgs/x-close-icon-808080.svg'
import { scale } from '../components/navbar'

export default function Search() {
    const { showBlurOverlay, setShowBlurOverlay } = useContext(AppContext)
    return (
        <Button
            variant=''
            className='p-0'
            onClick={() => setShowBlurOverlay('search')}
            css={scale}
        >
            {
                (showBlurOverlay === 'search') ?
                <img src={xCloseIcon} alt='X icon' /> : 
                <img src={searchIcon} alt='magnifying glass' />
            }
        </Button>
    )
}
