import React, { useContext } from 'react'
import { css } from '@emotion/react'
import AppContext from '../context/app'
import NavLinks from './nav-links'
import { base, padding, hover } from '../emotion/anchor-css'
import Container from 'react-bootstrap/Container'
import accountIconWhite from '../svgs/account-icon-white.svg'
import contactSupportIconWhite from '../svgs/contact-support-icon-white.svg'
import BrandLogoLink from './brand-logo-link'
import SearchButton from '../algolia/search-button'

const container = css({
    gap: 20,
    '@media(min-width: 1200px)': {
        gap: 56
    },
})

export const scale = css({
    transition: 'transform .1s ease-in',
    '&:hover': {
        transform: 'scale(1.1)'
    }
})

const support = theme => css({
    fontFamily: theme.fonts.primaryHeavy,
    transition: 'transform .1s',
    '&:hover': {
        transform: 'scale(1.05)',
    }
})

export default function Navbar() {
    const { setShowBlurOverlay, screenWidth } = useContext(AppContext)
    return (
        <nav className='sticky-top' style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <Container className='d-flex justify-content-between align-items-center'>
                <BrandLogoLink
                    onMouseEnter={() => setShowBlurOverlay('')}
                    className='d-inline-block'
                    style={{
                        paddingTop: 24,
                        paddingBottom: 24
                    }}
                />

                {/* NAVLINKS (> LARGE ONLY) */}
                <div className='d-lg-flex d-none' css={container}>
                    <NavLinks emotion={theme => [base, padding, hover(theme)]} />
                </div>

                {/* SUPPORT | SEARCH | ACCOUNT */}
                <div className='d-flex align-items-center' style={{ gap: 35 }}>
                    <a
                        href="https://support.alesis.com/"
                        target='_blank'
                        rel='noreferrer'
                        css={[base, support]}
                        onMouseEnter={() => setShowBlurOverlay('')}
                    >
                        {screenWidth < 992 ? <img src={contactSupportIconWhite} alt='' /> : 'SUPPORT'}
                    </a>
                    <SearchButton />
                    <a
                        href="https://profile.inmusicbrands.com/customer/account/login/"
                        target='_blank'
                        rel='noreferrer'
                        css={scale}
                    >
                        <img src={accountIconWhite} alt='' />
                    </a>
                </div>
            </Container>
        </nav>
    )
}
