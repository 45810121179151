import './src/styles/theme.scss'
import maxmind from './src/maxmind'

export const onInitialClientRender = () =>  maxmind()

export const onRouteUpdate = ({ location }) => {
    window.dispatchEvent(new Event('navigate'))
    const { hash } = location
    const el = hash ? document.querySelector(hash) : null
    if (el) window.requestAnimationFrame(() => window.scrollTo(0, el.offsetTop))
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition, }) => {
    const savedScrollPosition = getSavedScrollPosition(location)
    window.requestAnimationFrame(() => window.scrollTo(...savedScrollPosition))
    return false
}
